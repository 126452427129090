export const MESSAGES = {
    common: {
        emailPlaceholder: 'Adres e-mail',
        emailError: 'Podaj prawidłowy adres e-mail',
        requiredErrorEmail: 'Podaj adres e-mail',
        requiredErrorFirstname: 'Podaj Imię',
        requiredErrorLastname: 'Podaj Nazwisko',
        requiredErrorPhone: 'Podaj numer telefonu',
        nextButtonTitle: 'Kontyntynuj ',
        myListTitle: 'Moja lista',
        inputYourName: 'Imię i nazwisko',
        inputYourDowod: 'Opis dowodu',
        inputYourNameLastname: 'Wpisz Imię i Nazwisko świadka',
        thisIsRequired: 'Opisz naruszenie'
    },
    introduce: {
        title: 'Zgłoś naruszenie w miejscu pracy',
        text: 'Każdy, kto jest świadomy naruszenia w swoim środowisku zawodowym, może zostać sygnalistą. Jeśli wiesz o takim naruszeniu możesz zgłosić je wewnętrznie – temu służy moduł aplikacji lub dokonać zgłoszenia zewnętrznego – do właściwego organu',
        button: 'Zgłoś naruszenie',
        mylist: 'Moje zgłoszenia'
    },
    mylist: {
        title: 'Moje zgłoszenia',
        content: 'Zgłoszenie'
    },
    mylistContent: {
        title: 'Informacje kontaktowe ',
        sent: 'Wysłano',
        num: 'Numer odwołania',
        inderest: 'Zainteresowanie',
        forma: 'Forma odwołania',
        infoAboutSender: 'Informacje o wnioskodawcy',
        text: 'Tekst odwołania',
        affected: 'Dotknięty',
        responsible: 'Odpowiedzialny za naruszenia',
        witness: 'Świadkowie',
        evidence: 'Dowódy',
        nodata: 'Brak danych',
        public: 'Publiczny',
        private: 'Prywatny'
    },
    chooseAuthor: {
        title: 'Zgłoszenie dokonane:',
        affected: 'Zgłaszający (Sygnalista)',
        affectedDescription: 'Chcę zgłosić się osobiście',
        helping: 'Osoba pomagająca Zgłaszającemu (Sygnaliście)',
        helpingDescription: 'Dokonuję zgłoszenia w imieniu osoby trzeciej',
    },
    feedbackEmail: {
        title: 'Wpisz swój adres e-mail, aby otrzymać odpowiedź',
        emailPlaceholder: 'Adres e-mail',
    },
    formType: {
        title: 'Karta zgłoszenia',
        open: 'Z podaniem danych osobowych',
        openDescription: 'Twoje dane osobowe zostaną przekazane pracodawcy (imię, nazwisko, adres e-mail, telefon)',
        anonymous: 'Anonimowo',
        anonymousDescription: 'Podaj tylko dane do kontaktu, które nie pozwolą Cię zidentyfikować jako konkretnego pracownika, jednak zapewnią kontakt z Tobą (podanie tylko adresu e-mail)',
    },
    organizationInfo: {
        title: 'Dodaj organizację i wybierz naruszenie',
        organizationNamePlaceholder: 'Miejsce pracy',
        positionPlaceholder: 'Stanowisko',
        reasons: [
            'zamówień publicznych',
            'usług, produktów i rynków finansowych',
            'zapobiegania praniu pieniędzy i finansowaniu terroryzmu',
            'bezpieczeństwa produktów i ich zgodności z wymogami',
            'bezpieczeństwa transportu',
            'ochrony środowiska',
            'ochrony radiologicznej i bezpieczeństwa jądrowego',
            'zamówień publicznych',
            'usług, produktów i rynków finansowych',
            'zapobiegania praniu pieniędzy i finansowaniu terroryzmu',
            'bezpieczeństwa produktów i ich zgodności z wymogami',
            'bezpieczeństwa transportu',
            'ochrony środowiska',
            'ochrony radiologicznej i bezpieczeństwa jądrowego',
            'bezpieczeństwa żywności i pasz',
            'zdrowia i dobrostanu zwierząt',
            'zdrowia publicznego',
            'ochrony konsumentów',
            'ochrony prywatności i danych osobowych',
            'bezpieczeństwa sieci i systemów teleinformatycznych',
            'interesów finansowych Unii Europejskiej',
            'rynku wewnętrznego Unii Europejskiej, w tym zasad konkurencji pomocy państwa oraz opodatkowania osób prawnych',
        ],
    },
    personalInfo: {
        title: 'Proszę wypełnić dane osobowie',
        namePlaceholder: 'Imię',
        lastNamePlaceholder: 'Nazwisko',
        phonePlaceholder: 'Numer telefonu',
    },
    success: {
        title: 'Zgłoszenie wysłane',
        text: 'Informacja o rozpatrzeniu Twojego zgłoszenia zostanie wysłana na podany adres e-mail',
        complete: 'Zakończ',
    },
    violationDetails: {
        title: 'Opisz szczegółowo naruszenie',
        text: 'Jeżeli Masz dokumenty lub zdjęcia na okoliczność naruszenia załącz je',
        detailsPlaceholder: 'Treść zgłoszenia...',
        attachPhoto: 'Załącz dokument/zdjęcie',
    },
    affectedPersons: {
        title: 'Dodaj osoby pokrzywdzone i odpowiedzialne',
        text: 'Osoby, których dotyczy zgłoszenie naruszenia (np. współpracownik, kontrahent, itp.)',
        affected: 'Osoby pokrzywdzone naruszeniem',
        addAffected: 'Dodaj osobę',
        responsible: 'Osoby, które dopuściły się naruszenia',
        addResponsible: 'Dodaj osobę',
    },
    witnessesPersons: {
        title: 'Podaj inne dowody niż załączone do zgłoszenia',
        text: 'Zgłoszenie może zostać udokumentowane zebranymi dowodami i wykazem świadków.',
        witnesses: 'Dowód',
        addWitness: 'Dodaj dowód',
        evidences: 'Świadek',
        addEvidence: 'Dodaj świadka',
    },
    confirm: {
        title: 'Zapoznaj się z obowiązkami wynikającymi z wysłania zgłoszenia'
    }
};