import GLOBALS from '../constants/Globals';
import { commonStyles, Text, View } from '../components/Themed';
import { RootTabScreenProps } from "../types";
import React, {useState} from "react";
import NextButton from "../components/NextButton";
import { ActivityIndicator, StyleSheet } from "react-native";
import { Checkbox } from "react-native-paper";
import SignallerDataManager from "../services/SignallerDataManager";
import axios from "axios";
import {MESSAGES} from "../constants/Messages";

export default function ConfirmScreen({navigation}: RootTabScreenProps<'Confirm'>) {
    const [confirmCheckBox, setConfirmCheckBox] = useState(false)
    const [isLoading, setLoading] = useState(false);

    const onSubmit = () => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');

            if (token)
                SignallerDataManager.getInstance()?.setToken(token);

            const config = { headers: { 'Content-Type': 'multipart/form-data;charset=utf-8; boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL' } };
            const fulldata = JSON.stringify(SignallerDataManager.getInstance()?.getData());
            
            const formData = new FormData()

            const myFileInput = document.getElementById('filesAttach');

            if (myFileInput) {
                const attachedFiles = myFileInput.files;
                for (let i = 0, numFiles = attachedFiles.length; i < numFiles; i++) {
                    let file = attachedFiles[i];
                    formData.append('files[]', file, file.name);
                  }
            };

            if(fulldata){
                formData.append('data', fulldata); 
            }
            
            axios.post(GLOBALS.API_URL+'/v2.5/signaller/send', formData, config)
            .then(() => {
                navigation.navigate('Success');
            });

        } catch (e) {
            console.log(e);
        }
    };

    return (
        <View style={[commonStyles.container, commonStyles.content]}>
            {isLoading ? <ActivityIndicator style={{height:'100%'}}/> :
                <>
                    <View style={{ height: 'calc(100% - 50px)', overflow: 'scroll', paddingBottom: 80 }}>
                        <Text style={commonStyles.title}>{MESSAGES.confirm.title}</Text>
                        <Text style={styles.p}>Niniejszym powierzam z dniem następujące obowiązki związane z:</Text>
                        <Text style={styles.p}>• z zapewnieniem wdrożenia Regulaminu (kanałów i procedur zgłoszeń wewnętrznych), w tym zapewnienie zasobów niezbędnych do realizacji praw i obowiązków wynikających z Regulaminu [(i) zapewnienie środków finansowych, organizacyjnych i kadrowych, (ii) ustalenie i podział kompetencji pomiędzy pracowników, w sposób zapewniający efektywność kanałów i procedur zgłoszeń wewnętrznych]</Text>
                        <Text style={styles.p}>• z wykonywaniem zadań wynikających z Regulaminu (wsparcie wdrożeniem kanałów i procedury zgłoszeń wewnętrznych) uczestniczeniem w realizacji Regulaminu (wsparciem kanałów i procedury zgłoszeń wewnętrznych), w szczególności poprzez: (i) osobiste zaangażowanie w rzeczywiste wdrożenie Regulaminu (zapewnienie kanałów i procedury zgłoszeń wewnętrznych), (ii) rozwój systemu przeciwdziałania powstawania naruszeniom prawa, w tym korupcji, (iii) promowanie kultury organizacyjnej opartej na przeciwdziałaniu powstawania naruszeniom prawa, w tym korupcji, (iv)</Text>
                        <Text style={styles.p}>• sprawowaniem bezpośredniego nadzoru nad skutecznością wdrożonego Regulaminu (kanałów i procedury zgłoszeń wewnętrznych) w szczególności poprzez: (i) monitorowanie przestrzegania ustalonych zasad postępowania, (ii) promowanie kultury organizacyjnej opartej na przeciwdziałaniu powstawania naruszeniom prawa, w tym korupcji, (iii) zgłaszanie naruszeń właściwym organom, (iv).</Text>
                        <View style={styles.confirmCheckbox}>
                            <Checkbox
                                color='#0379F7'
                                status={confirmCheckBox ? 'checked' : 'unchecked'}
                                onPress={() => {
                                    setConfirmCheckBox(!confirmCheckBox);
                                } } />
                            <Text style={{ paddingTop: 10, fontWeight: 'bold' }}>Zapoznaj się z regulaminem</Text>
                        </View>
                    </View>
                    <NextButton disabled={!confirmCheckBox} onPress={onSubmit} />
                </>
            }
        </View>
    );
}

const styles = StyleSheet.create({
    p: {
        marginBottom: 20,
    },
    confirmCheckbox: {
        width: '100%',
        flex: 1,
        flexDirection: 'row',
        marginLeft: 0
    }
});